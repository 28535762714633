import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import { useForm } from "react-hook-form";
import { gql, useLazyQuery } from "@apollo/client";
import { useHistory } from 'react-router-dom';

function WidgetForm(props) {
  const GET_AVAILABLE = gql`
    query Available($tourId: UUID!, $start: String!, $end: String!) {
      available(tourId: $tourId, start: $start, end: $end) {
        ok
        message
        edges {
          id
          name
          price
          checkIn
          minPeople
          maxPeople
        }
      }
    }
  `;
  const history = useHistory();
  const today = new Date()
  const tomorrow = new Date(today)
    tomorrow.setDate(tomorrow.getDate() + 1)
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      adult: 0,
      child: 0,
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      remark: "",
    },
  });

    
  const onSubmit = (data) =>  {
    let order = {
      order_date: startDate,
      order_items: [
        {
          "item_id": adultAvailables.id,
          "item_name": adultAvailables.name,
          "qty": Number(adult),
          "price": price,
          "item_type": "Adult package"
        },
      ],
      "product_id": props.tour.id,
      "product_name": props.tour.title,
      "product_highlight": props.tour.highlight,
      "product_type": "package",
      "remark": data.remark,
      "total": 0
    }
    if(child > 0){
      order.order_items.push(
        {
          "item_id": childAvailables.id,
          "item_name": childAvailables.name,
          "qty":  Number(child),
          "price": childPrice,
          "item_type": "Child package"
        }
      )
    }
    order.total = grandTotal
    if(grandTotal > 0){
      scrollTop();
      history.push('/check-out', { order });
    }
  }

  const [getAvailables, availablesRes] = useLazyQuery(GET_AVAILABLE);
  const [startDate, setStartDate] = useState(tomorrow);
  const [available, setAvailable] = useState([]);
  const [availables, setAvailables] = useState([]);
  const [total, setTotal] = useState(0);
  const [childTotal, setChildTotal] = useState(0);
  const [adultAvailables, setAdultAvailables] = useState({});
  const [childAvailables, setChildAvailables] = useState({});
  const [adult, setAdult] = useState(0);
  const [child, setChild] = useState(0);
  const [price, setPrice] = useState(0);
  const [max, setMax] = useState(0);
  const [childPrice, setChildPrice] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    let content = [];
    //console.log("set tomorrow")
    availablesRes.data?.available?.edges.some((element) => {
      const myDate = element.checkIn.split(" ");
      let checkIn = new Date(myDate[0]);
      if (checkIn.toDateString() === startDate.toDateString()) {
        content.push(element);
      }
    });
    setAvailable(content);
  }, [startDate]);

  useEffect(() => {
    const today = new Date();
    const sDay = new Date(today.setDate(today.getDate()));
    const eDay = new Date(today.setDate(today.getDate() + 180));
    getAvailables({
      variables: {
        tourId: props.tour.id,
        start: sDay.toISOString(),
        end: eDay.toISOString(),
      },
    });
    ////console.log("set date")
    //setStartDate(tomorrow)
  }, []);

  useEffect(() => {

    //console.log("set available")
    calAdultPrice();
    calChildPrice();
  }, [available]);

  const calAdultPrice = () => {
    available.some((element) => {
        if( element.maxPeople > max){
           setMax(element.maxPeople);
            }
      if (
        element.minPeople <= (Number(adult)+Number(child)) &&
        element.maxPeople >=  (Number(adult)+Number(child)) &&
        element.name === "Adult"
      ) {
        setAdultAvailables(element)
        setPrice(element.price);
        
        calPrice();
      }
    });
  };

  useEffect(() => {
    calAdultPrice();
    calChildPrice();
  }, [adult, child]);

  const calChildPrice = () => {
    available.some((element) => {
      if (
        element.minPeople <= (Number(adult)+Number(child)) &&
        element.maxPeople >=  (Number(adult)+Number(child)) &&
        element.name === "Child"
      ) {
        //console.log("set child")
        setChildAvailables(element)
        setChildPrice(element.price);
        calPrice();
      }
    });
  };

  const calPrice = () => {
      let total = Number(adult)+Number(child);
      //console.log(total)
      setGrandTotal(value => 0)
      setTotal(value => 0)
          for (let i = 0; total > 0; i++) {
           if (total >= max){
                 available.some((element) => {
                     if (
                       element.minPeople <= max &&
                       element.maxPeople >=  max &&
                       element.name === "Adult"
                     ) {
                       setTotal(total + (max*element.price))
                       total = total-max
                     }
                   });
               }else{
                       available.some((element) => {
                         if (
                           element.minPeople <= total &&
                           element.maxPeople >=  total &&
                           element.name === "Adult"
                         ) {
                             setTotal(total + (total*element.price))
                             total = 0
                         }
                       });
                   }
          }

     
      }

      useEffect(() => {
        let tAdult = (Number(price)*Number(adult));
        let tChild = (Number(childPrice)*Number(child))
        let gtotal = Number(tAdult) + Number(tChild);
       setGrandTotal(value => Number(gtotal))
      }, [total]);

  const getChildItem = () => {
    available.some((element) => {
      if (
        element.minPeople <= (Number(adult)+Number(child)) &&
        element.maxPeople >=  (Number(adult)+Number(child)) &&
        element.name === "Child"
      ) {
       return element
      }
    });
  };


  // const isAvailable = (date) => {
  //   const isFound = availablesRes.data.available.edges.some((element) => {
  //     let checkIn = new Date(element.checkIn);
  //     if (checkIn.toDateString() === date.toDateString()) {
  //       return true;
  //     }
  //   });
  //   return isFound;
  // };

  if (availablesRes.loading) return "Loading...";
  if (availablesRes.error) return `Error! ${availablesRes.error.message}`;

  return (
    <>
      <aside className="package-widget-style-2 widget-form">
        <div className="widget-title text-center d-flex justify-content-between">
          <h4>Price start from</h4>
          <h3 className="widget-lavel">
            <span>฿{props.tour.price} / Person</span>
          </h3>
        </div>
        <div className="widget-body">
          <form onSubmit={handleSubmit(onSubmit)} id="booking-form">
            <div className="booking-form-wrapper">
              <div className="custom-input-group">
                <DatePicker
                  closeOnScroll={(e) => e.target === document}
                  selected={startDate}
                  minDate={tomorrow}
                  onChange={(date) => setStartDate(date)}
                 // filterDate={isAvailable}
                />

                {available.some((item) => (
                  <span className="me-1">
                    {item.name} ({item.minPeople} - {item.maxPeople}):{" "}
                    {item.price}
                  </span>
                ))}
              </div>
              {startDate > new Date() ?
              <>
              <div className="row mt-4">
                
              <div className="col-sm-3 p-4">
                Adult
                </div>
                  <div className="col-sm-9">
                  <input name="adult" type="number" min="1" max="50" value={adult} onChange={(e) =>  {
                    setAdult(e.target.value);   
    calAdultPrice();
    calChildPrice();
                        }} />
                </div>
              </div>
   
              <div className="row">
              <div className="col-sm-3 p-4">
              Child
                </div>
                <div className="col-sm-9">
                <input name="child" type="number" min="0" max="20" value={child} onChange={(e) =>  {
                    setChild(e.target.value);   
    calAdultPrice();
    calChildPrice();
                        }} />
            
                </div>
              </div>
              {
                adult > 0 && price > 0 ? (<div className="col-sm-12">
                Adult <b>{price}</b>{" Baht/Person"}
              </div>) : ""
              }
               {
                child > 0 && childPrice > 0 ? (<div className="col-sm-12">
                Child <b>{childPrice}</b>{" Baht/Person"}
              </div>) : ""
              }
                  {
                grandTotal > 0 ? (<div className="col-sm-12 fs-5">
                Total: <b>{grandTotal}</b>
              </div>) : ""
              }</> : <div></div>
}

              <div className="custom-input-group">
                <div className="submite-btn">
                  <button type="submit">Book Now</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </aside>
    </>
  );
}

export default WidgetForm;
