import React from "react";
import WidgetForm from "./../packageDetails/WidgetForm";
import WidgetPackageOffer from "./../packageDetails/WidgetPackageOffer";
import PackageHeader from "./../packageDetails/PackageHeader";
import PackageDetailsTab from "./../packageDetails/PackageDetailsTab";

function PackageStandardWrap(props) {
  
  return (
    <>
      <div className="package-details-wrapper pt-110">
        <div className="container">
        <div className="row">
            <div className="col-lg-8">
              <div className="tour-package-details">
                <PackageHeader tour={props.tour} />
                <PackageDetailsTab id={props.tour.id} tour={props.tour} />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="package-sidebar">
                <WidgetForm tour={props.tour}/>
                <WidgetPackageOffer />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PackageStandardWrap;
